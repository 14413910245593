<template>
  <r-block no-padding>
    <r-block
      space-between
      no-padding
      col
    >
      <r-text> Нарушения </r-text>
      <r-button
        type="primary"
        icon="add-plus"
        @click="visible = true"
      >
        Добавить
      </r-button>
    </r-block>

    <r-block
      v-if="ready"
      no-padding
    >
      <violation-item
        v-for="(violation, i) in violations"
        :key="violation.id"
        :title="`Нарушение ${i + 1}`"
        :source="source"
        :violation="violation"
        :edit-mode="editMode"
        @handle-update="$emit('handle-update')"
        @handle-change="$emit('handle-change')"
      />
    </r-block>

    <el-dialog
      v-if="visible"
      title="Выбор нарушений"
      width="max-content"
      top="5vh"
      :visible="visible"
      :before-close="beforeClose"
      destroy-on-close
      append-to-body
    >
      <add-modal
        :source="source"
        @changed="modalActive = true"
        @handle-update="$emit('handle-update')"
        @close="visible = false"
      />
    </el-dialog>
  </r-block>
</template>

<script>
export default {
  components: {
    addModal: () => import('./modal'),
    violationItem: () => import('./violation-item')
  },
  props: {
    source: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ready: false,
      visible: false,
      modalActive: false,
      violations: null
    }
  },
  watch: {
    source: {
      handler: function() {
        this.ready = false
        this.initViolation()
      },
      deep: true
    }
  },
  created() {
    this.initViolation()
  },
  methods: {
    initViolation() {
      this.violations = this.source.act_violations?.map(av => {
        return {
          id: av.id,
          norm_doc_requirements: av.norm_doc_requirements,
          description: av?.bdd_violation?.description
        }
      }) || []
      this.modalActive = false

      this.$nextTick(() => (this.ready = true))
    },
    beforeClose() {
      const title = this.$t('index:' + 'before:title')
      const message = this.$t('index:' + 'before-cancel:text')
      const confirmButtonText = this.$t('index:' + 'button-confirm')
      const cancelButtonText = this.$t('index:' + 'button-cancel')

      if (this.modalActive) {
        this.$confirm(message, title, {
          customClass: 'r-message-box',
          closeOnPressEscape: true,
          closeOnClickModal: false,
          type: 'warning',
          confirmButtonText,
          cancelButtonText
        })
          .then(() => {
            this.visible = false
          })
          .catch(() => {})
      } else {
        this.visible = false
      }
    }
  }
}
</script>
